import React, { useState } from 'react'

import { Icon } from '@components/React'
import { CookieType } from '@containers/new/CookiesPopup'
import { cx } from '@emotion/css'
import { FormControlLabel, Switch } from '@mui/material'

import {
	CookieDescription,
	CookieItem,
	sectionIcon, sectionOpenIcon,
	SwitchLabel, ToggleButton,
} from '@containers/new/CookiesPopup/CookiePopup.Styled'

interface CookieInfo {
	cookieType: CookieType,
	isSelected: boolean,
	switchSelection: (type: CookieType, value: boolean) => void,
}

const COOKIE_DATA = {
	statistical: {
		cookieName: 'Statystyczne',
		isRequired: false,
		details: 'Pliki Cookies Statystyczne pomagają Administratorowi zrozumieć, w jaki sposób Użytkownicy wchodzą w interakcje z Serwisem, poprzez anonimowe zbieranie i raportowanie informacji. Te pliki Cookies są domyślnie wyłączone, mogą zostać włączone za zgodą Użytkownika poprzez kliknięcie w odpowiednie pole wyboru. Użytkownik może wycofać zgodę w każdej chwili.',
	},
	marketing: {
		cookieName: 'Marketingowe',
		isRequired: false,
		details: 'Pliki Cookies Marketingowe wykorzystywane są do śledzenia Użytkowników na stronach internetowych. Celem jest wyświetlanie reklam, które są istotne i angażujące dla poszczególnych Użytkowników, a tym samym bardziej wartościowe dla wydawców i reklamodawców zewnętrznych.',
	},
	required: {
		cookieName: 'Niezbędne',
		isRequired: true,
		details: 'Bez Plików Cookies Niezbędnych Użytkownik nie może korzystać z Serwisu. Te pliki są niezbędne do prawidłowego funkcjonowania Serwisu i są domyślnie włączone.',
	},
}

const CookieInfo = (props: CookieInfo) => {
	const [ isOpen, setIsOpen ] = useState<boolean>(false)
	const [ isSelected, setIsSelected ] = useState<boolean>(props.isSelected)

	const { cookieName, isRequired, details } = COOKIE_DATA[props.cookieType]

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsSelected(event.target.checked)
		props.switchSelection(props.cookieType, event.target.checked)
	}

	const CookieLabel = <SwitchLabel><strong>{ cookieName }</strong>{ isRequired && ' (zawsze aktywne)' }</SwitchLabel>

	return (
		<CookieItem>
			<FormControlLabel
				control={ <Switch checked={ isSelected } onChange={ handleChange }/> }
				label={ CookieLabel }
				disabled={ isRequired }
			/>
			<ToggleButton onClick={ () => setIsOpen(!isOpen) }>
				<Icon type="chevron-down" className={ cx(sectionIcon, isOpen ? sectionOpenIcon : '') }/>
			</ToggleButton>
			{ isOpen && <CookieDescription>{ details }</CookieDescription> }
		</CookieItem>
	)
}

export default CookieInfo
