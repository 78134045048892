import Emotion from '@emotion/styled'
import MQ from '@styles/MediaQueries'

type Breakpoint = 'Small' | 'Xmobile' | 'Medium' | 'Large' | 'Xlarge' | 'Xxlarge'

interface PageTitleProps {
  centered?: boolean,
  hideFor?: Breakpoint,
  showFor?: Breakpoint,
}

export default Emotion('h1')<PageTitleProps>((props) => ({
	display: props.showFor ? 'none' : 'block',
	fontSize: 18,
	fontWeight: 300,
	margin: '0 0 20px 0',
	textAlign: props.centered ? 'center' : 'left',
	textTransform: 'uppercase',

	...(props.showFor ? {
		[MQ[props.showFor]]: {
			display: 'block',
		},
	} : {}),

	...(props.hideFor ? {
		[MQ[props.hideFor]]: {
			display: 'none',
		},
	} : {}),
}))
