import Color from '@styles/Color'

import Style from '@emotion/styled'

interface DropdownLinkProps {
	withIcon?: boolean
}

export const Root = Style('div')`
  background: ${Color.White};
  border: solid 1px ${Color.Blue[800]};
  display: block;
  position: fixed;
  text-align: left;
  white-space: nowrap;
`

export const DropdownLink = Style('a')<DropdownLinkProps>`
  display: block;
  padding: 3px 5px;

  ${({ withIcon }) =>
		withIcon
			? `
					> span:first-child {
						margin-right: 5px;
					}
			  `
			: null}
`

export const DropdownItem = Style('div')`
  display: block;
  padding: 3px 5px;
`
