/* eslint-disable */
import MDCSelect from '@material/react-select'
import * as React from 'react'
import { css } from '@emotion/css'

import Emotion from '@emotion/styled'

import { Icon } from '@components/React'
import { Button } from '@components/React/MDC'

interface SelectProps {
  canClear?: boolean,
  children: any,
  disabled?: boolean,
  label?: string,
  outlined?: boolean,
  required?: boolean,
  value?: string | number,
  [key: string]: any,
}

interface MDCSelectProps {
  canClear?: boolean,
  children: any,
  disabled?: boolean,
  label?: string,
  outlined?: boolean,
  required?: boolean,
  value?: string,
  [key: string]: any,
}

const Container = Emotion('div')({
	position: 'relative',
})

const clearButton = css({
	borderRadius: '50%',
	fontSize: 18,
	margin: 0,
	maxHeight: 30,
	maxWidth: 30,
	minHeight: 30,
	minWidth: 30,
	padding: 0,
	position: 'absolute',
	right: 0,
	top: 20,
})

const clearIcon = css({
	margin: '0 !important',
})

export default class Select extends React.Component<SelectProps> {

	public handleClear = () => {
		// eslint-disable-next-line react/prop-types
		if (typeof this.props.onChange === 'function') {
			// eslint-disable-next-line react/prop-types
			this.props.onChange({ target: { value: '' } })
		}
	}

	public render() {
		const safeProps = { ...this.props }
		const containerProps = {
			// eslint-disable-next-line react/prop-types
			className: this.props.className,
			// eslint-disable-next-line react/prop-types
			style: this.props.style,
		}

		if (safeProps.value) {
			safeProps.value = String(safeProps.value)
		}

		if (safeProps.label) {
			safeProps.label = `${safeProps.label} `
		}

		if (safeProps.label && safeProps.value) {
			safeProps.floatingLabelClassName = 'mdc-floating-label--float-above'
		}

		delete safeProps.className

		return (
			<Container { ...containerProps }>
				<MDCSelect { ...safeProps as MDCSelectProps }/>
				{this.props.canClear && this.props.value && (
					<Button className={ clearButton }
						color="black"
						onClick={ this.handleClear }
						type="button"
						view="text"
					>
						<Icon className={ clearIcon } type="times" weight="light"/>
					</Button>
				)}
			</Container>
		)
	}

}
