import React from 'react'

import Emotion from '@emotion/styled'
import Color from '@styles/Color'

const Root = Emotion('label')({
	display: 'inline-block',
	position: 'relative',
	verticalAlign: 'middle',
})

const Input = Emotion('input')({
	left: 0,
	opacity: 0,
	position: 'absolute',
	top: 0,
	zIndex: 1,

	'&:checked + span::before': {
		color: Color.Blue[800],
		content: '"\\F00C"',
		fontFamily: '"Font Awesome 6 Pro"',
		fontSize: 14,
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '14px',
		verticalAlign: 'top',
	},
})

const Visualization = Emotion('span')({
	background: Color.White,
	border: `1px solid ${ Color.Gray[500] }`,
	cursor: 'pointer',
	display: 'block',
	height: 15,
	position: 'relative',
	width: 15,
	zIndex: 2,
})

export default function Checkbox(props) {
	return (
		<Root>
			<Input { ...props } type="checkbox"/>
			<Visualization/>
		</Root>
	)
}
