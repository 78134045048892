/* eslint-disable */
import * as React from 'react'
import Emotion from '@emotion/styled'

const Root: any = Emotion('div')({
	display: 'flex',
	flexWrap: 'wrap',
	marginLeft: 'auto',
	marginRight: 'auto',
	maxWidth: 1440,
	minWidth: 300,
	width: '100%',

	'& &': {
		marginLeft: -15,
		marginRight: -15,
	},
})

export default function Row(props) {
	return <Root { ...props }/>
}
