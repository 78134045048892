/* eslint-disable */
import { getBreakpoint, isBreakpointValid } from '@styles/MediaQueries'
import { MediaQueryObject, MediaQueryType } from '@sharedAssets/interfaces/styles'

import React, { Component } from 'react'

import { Icon } from '@components/React'
import {
	IconChildrenExpand, Table, TableCell, TableCellHeader, TableHead,
	TableRow, TableRowChildrenContainer, TableRowChildrenToggler, TableRowChildrenTogglerContainer,
	TableSortButton,
} from './Table.Styled'


interface TableColumn {
  allowOverflow?: boolean,
  column: number[] | MediaQueryObject<number[]>,
  key: string,
  render?: (data: any, row: {[key: string]: any}, isChild?: boolean) => any,
  row: number[] | MediaQueryObject<number[]>,
  showTitleFor?: MediaQueryType,
  sortable?: boolean,
  title?: any,
}

export interface SortData {
  column: string,
  order: 'asc' | 'desc'
}

interface TableComponentProps {
  childrenKey?: string,
  childrenIncludeSelf?: boolean,
  collapseText?: (collapse: boolean) => string,
  collapseColumn?: number | MediaQueryObject<number>,
  columns: TableColumn[],
	columnGap?: number,
  grid: TableGridTemplateColumns,
  onSort: (sort: SortData) => void,
  rowGrid: TableRowGridTemplate,
  rowKey?: string,
  rows: Array<{[key: string]: any}>,
  sortDetails?: SortData,
}

interface TableComponentState {
  expandedChildren: number[],
  previousBreakpoint: MediaQueryType,
  sort: SortData,
}

export default class TableComponent extends Component<TableComponentProps, TableComponentState> {

	public static getDerivedStateFromProps = (props: TableComponentProps) => {
		if (props.sortDetails !== void 0) {
			return {
				sort: props.sortDetails,
			}
		}

		return {}
	}
	public state = {
		expandedChildren: [],
		previousBreakpoint: getBreakpoint(),
		sort: null,
	}

	public componentDidMount() {
		window.addEventListener('resize', this.updatePreviousBreakpoint)
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this.updatePreviousBreakpoint)
	}

	/**
   * Breakpoint is used to properly render table head.
   * setState updates component and makes rerender with correct grid-columns.
   */
	public updatePreviousBreakpoint = () => {
		if (this.state.previousBreakpoint !== getBreakpoint()) {
			this.setState({
				previousBreakpoint: getBreakpoint(),
			})
		}
	}

	public handleSort = (column: string) => this.setState((prevState) => {
		let sort = { ...prevState.sort }

		const order = sort.column !== column ?
			'desc' : sort.order === 'desc' ?
				'asc' : null

		sort = order ? { column, order } : null

		if (typeof this.props.onSort === 'function') {
			this.props.onSort(sort)
		}

		return { sort }
	})

	public handleChildrenToggle = (rowId: number) => this.setState((prevState) => {
		const expandedChildren = [...prevState.expandedChildren]

		if (expandedChildren.indexOf(rowId) >= 0) {
			expandedChildren.splice(expandedChildren.indexOf(rowId), 1)
		} else {
			expandedChildren.push(rowId)
		}

		return { expandedChildren }
	})

	public areChildrenExpanded = (rowId: number): boolean => this.state.expandedChildren.indexOf(rowId) >= 0

	public render() {
		const { props, state } = this
		let tableHeaderColumnCount = 0

		const tableHeaders = props.columns.map((column) => {
			if (!column.showTitleFor || isBreakpointValid(column.showTitleFor)) {
				tableHeaderColumnCount++
			}

			return (
				<TableCell
					showFor={ column.showTitleFor }
					key={ column.key }
					column={ [tableHeaderColumnCount] }
				>
					<TableCellHeader>
						{column.title}
					</TableCellHeader>
					{column.sortable && (
						<TableSortButton onClick={ () => this.handleSort(column.key) }>
							<Icon
								type={
									state.sort && state.sort.column === column.key ?
										(state.sort.order === 'asc' ? 'sort-up' : 'sort-down') :
										'sort'
								}
								weight="solid"
							/>
						</TableSortButton>
					)}
				</TableCell>
			)
		})

		const tableRows = props.rows.map((row, rowIndex) => (
			<TableRow
				className="test-table-row"
				key={ props.rowKey ? row[props.rowKey] : rowIndex }
				grid={ props.rowGrid }
				gridRow={ rowIndex + 2 }
				hasChildren={
					Array.isArray(row[props.childrenKey]) &&
          row[props.childrenKey].length > (props.childrenIncludeSelf ? 1 : 0)
				}
			>
				{props.columns.map((column) => (
					<TableCell
						allowOverflow={ column.allowOverflow }
						column={ column.column }
						key={ `${props.rowKey ? row[props.rowKey] : rowIndex}-${column.key}` }
						row={ column.row }
					>
						{column.render ? column.render(row[column.key], row) : row[column.key]}
					</TableCell>
				))}
				{
					Array.isArray(row[props.childrenKey]) &&
          row[props.childrenKey].length > (props.childrenIncludeSelf ? 1 : 0) && (
						<TableRowChildrenContainer>
							<TableRowChildrenTogglerContainer column={ this.props.collapseColumn }>
								<TableRowChildrenToggler onClick={ () => this.handleChildrenToggle(row.id) }>
									{this.props.collapseText(this.areChildrenExpanded(row.id))}
									<Icon
										className={ IconChildrenExpand }
										type={ this.areChildrenExpanded(row.id) ? 'caret-up' : 'caret-down' }
										weight="solid"
									/>
								</TableRowChildrenToggler>
							</TableRowChildrenTogglerContainer>
							{this.areChildrenExpanded(row.id) && row[props.childrenKey].map((subRow, subRowIndex) => (
								<TableRow
									className="test-table-row-child"
									key={ `sub-${props.rowKey ? row[props.rowKey] : subRowIndex}` }
									grid={ props.rowGrid }
									gridRow={ subRowIndex + 2 }
									isChild
								>
									{props.columns.map((subColumn) => (
										<TableCell
											allowOverflow={ subColumn.allowOverflow }
											column={ subColumn.column }
											key={ `${props.rowKey ? row[props.rowKey] : rowIndex}-${subColumn.key}` }
											row={ subColumn.row }
										>
											{subColumn.render ? subColumn.render(subRow[subColumn.key], subRow, true) : subRow[subColumn.key]}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableRowChildrenContainer>
					)
				}
			</TableRow>
		))

		return (
			<Table className="test-table" grid={ props.grid } rows={ props.rows.length + 1 } columnGap={ props.columnGap || 0 }>
				<TableHead>
					{tableHeaders}
				</TableHead>
				{tableRows}
			</Table>
		)
	}

}
