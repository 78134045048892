import Styled from '@emotion/styled'
import { css } from '@emotion/css'

import { Alpha, Black, Blue, Gray, White } from '@styles/Color'
import { DisplayFlex, Flex } from '@styles/Flex'
import {
	AlignSelf,
	DisplayGrid,
	GridTemplateColumns,
	GridTemplateRows,
	JustifySelf,
} from '@styles/Grid'
import { MediaQuery } from '@styles/MediaQueries'

/*
 * Classes
 */

export const closeButton = (sizeClose = 18) =>
	css({
		fontSize: 20,
		height: 40,
		margin:
			sizeClose !== 18
				? '0 15px 0 calc(100% - 65px)'
				: '0 20px 0 calc(100% - 60px)',
		minHeight: 40,
		minWidth: 0,
		padding: 0,
		width: 40,

		'& > span': {
			fontSize: `${sizeClose}px !important`,
			height: `${sizeClose}px !important`,
			width: `${sizeClose}px !important`,
		},

		[MediaQuery.Medium]: {
			margin:
				sizeClose !== 18
					? '0 40px 0  calc(100% - 40px)'
					: '0 20px 0  calc(100% - 60px)',
		},
	})

/*
 * Components
 */

export const Backdrop = Styled('div')<{ backdropColor?: boolean }>(
	({ backdropColor }) => ({
		alignContent: 'center',
		background: backdropColor ? Alpha(Black, 0.15) : Alpha(Blue[800], 0.6),
		bottom: 0,
		...DisplayGrid(),
		...GridTemplateColumns('1fr'),
		...GridTemplateRows('1fr'),
		left: 0,
		overflow: 'hidden',
		position: 'fixed',
		right: 0,
		top: 0,
		zIndex: 1000,
	}),
)

interface ContainerProps {
	notFullScreen?: boolean
	grayish?: boolean
	fullScreenMedium?: boolean
	borderRounded?: boolean
	noMaxHeight?: boolean
	noTopBottomPadding?: boolean
}

export const Container = Styled('div')<ContainerProps>(
	({
		notFullScreen,
		grayish,
		fullScreenMedium,
		borderRounded,
		noMaxHeight,
		noTopBottomPadding
	}) => ({
		...AlignSelf('center'),
		background: grayish ? Gray[50] : White,
		...DisplayFlex('column'),
		height: notFullScreen ? 'auto' : '100svh',
		...JustifySelf('center'),
		maxHeight: notFullScreen || !noMaxHeight ? 'calc(100vh - 30px)' : null,
		maxWidth: notFullScreen ? 'calc(100vw - 30px)' : null,
		minWidth: notFullScreen ? 290 : null,
		overflow: 'auto',
		padding: noTopBottomPadding ? 0 : '20px 0 40px 0',
		position: notFullScreen ? 'relative' : 'fixed',
		width: notFullScreen ? ['auto', 'fit-content'] : '100svw',

		[MediaQuery.Medium]: {
			height: fullScreenMedium || !noMaxHeight ? '100vh' : ['auto', 'fit-content'],
			maxHeight: fullScreenMedium ? '100vh' : 'calc(100vh - 30px)',
			maxWidth: fullScreenMedium ? '100vw' : 'calc(100vw - 30px)',
			minWidth: 320,
			padding: noTopBottomPadding ? 0 : '8px 0 0 0',
			position: fullScreenMedium ? 'fixed' : 'relative',
			width: fullScreenMedium ? '100vw' : ['auto', 'fit-content'],
		},

		[MediaQuery.Large]: {
			borderRadius: borderRounded ? 15 : 0,
			height: ['auto', 'fit-content'],
			maxHeight: noMaxHeight ? null : 'calc(100vh - 30px)',
			maxWidth: 'calc(100vw - 30px)',
			position: 'relative',
			width: ['auto', 'fit-content'],
		},
	}),
)

export const Content = Styled('div')({
	...Flex(1),
	overflow: 'auto',
	padding: '0 16px',
	width: '100%',
})

export const ModalFooter = Styled('div')({
	padding: 16,
})

export const ModalHeader = Styled('div')({
	padding: 16,
})

export const ModalTitle = Styled('h1')({
	fontSize: 18,
	fontWeight: 700,
	lineHeight: '24px',
	margin: 0,
	padding: '0 16px',

	[`& + ${ModalHeader}`]: {
		paddingTop: '0 !important',
	},

	[MediaQuery.Xmobile]: {
		textAlign: 'center',
	},
})
