import { HTMLProps } from 'react'

import Emotion from '@emotion/styled'
import MediaQuery from '@styles/MediaQueries'

interface ColumnProps extends HTMLProps<HTMLDivElement> {
	noPadding?: boolean
	small?: number
	xmobile?: number
	medium?: number
	large?: number
	xlarge?: number
	xxlarge?: number
}

export const Column = Emotion('div')(
	({
		noPadding,
		small = 12,
		xmobile,
		medium,
		large,
		xlarge,
		xxlarge,
	}: ColumnProps) => ({
		paddingLeft: noPadding ? null : 15,
		paddingRight: noPadding ? null : 15,

		...(small
			? {
				[MediaQuery.Small]: {
					width: `${ (small / 12) * 100 }%`,
				},
			  }
			: {}),

		...(xmobile
			? {
				[MediaQuery.Xmobile]: {
					width: `${ (xmobile / 12) * 100 }%`,
				},
			  }
			: {}),

		...(medium
			? {
				[MediaQuery.Medium]: {
					width: `${ (medium / 12) * 100 }%`,
				},
			  }
			: {}),

		...(large
			? {
				[MediaQuery.Large]: {
					width: `${ (large / 12) * 100 }%`,
				},
			  }
			: {}),

		...(xlarge
			? {
				[MediaQuery.Xlarge]: {
					width: `${ (xlarge / 12) * 100 }%`,
				},
			  }
			: {}),

		...(xxlarge
			? {
				[MediaQuery.Xxlarge]: {
					width: `${ (xxlarge / 12) * 100 }%`,
				},
			  }
			: {}),
	}),
)

export default Column
