/* eslint-disable */
import * as React from 'react'

export declare type IconWeight = 'regular' | 'light' | 'solid' | 'brand' | 'duotone'

interface IconProps extends React.HTMLProps<HTMLSpanElement> {
  className?: string,
  innerRef?: React.Ref<any>,
  style?: React.CSSProperties,
  type: string,
  weight?: IconWeight,
}

export const IconComponent = (props: IconProps) => {
	const safeProps = { ...props }
	const { className = '', type, weight = 'regular' } = safeProps

	delete safeProps.type

	return <span { ...safeProps }
		className={ [ `fa${ weight.slice(0, 1) }`, `fa-${ type }`, className ].join(' ') }
		ref={ props.innerRef }
		style={ {
			fontFamily: 'Font Awesome 6 Pro',
			fontWeight: weight === 'light' ? 300 : weight === 'solid' ? 800 : weight === 'duotone' ? 900 : 400,
			lineHeight: 'initial',
			...safeProps.style,
		} }/>
}

export const Icon = React.memo(IconComponent)

export default Icon
