import Emotion from '@emotion/styled'
import Color from '@styles/Color'

declare global {
	type CardElevation = 0 | 1 | 2 | 3 | 4 | 5
}

interface CardProps {
	bgColor?: string
	elevation?: CardElevation
	noPadding?: boolean
}

const boxShadow = {
	0: 'none',
	1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
	2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
	3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
	4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
	5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
}

export const Card = Emotion('div')<CardProps>(
	({ bgColor = Color.White, elevation = 0, noPadding }) => ({
		background: bgColor,
		boxShadow: boxShadow[elevation],
		padding: noPadding ? 0 : 16,
		position: 'relative',
		transition: 'box-shadow 0.2s ease',
	}),
)

export default Card
